html,
body,
#root {
  padding: 0;
  margin: 0;

  width: 100%;
  height: 100%;
}

.fullHeight {
  min-height: 100vh !important;
}
